import { CurrencyId } from 'common';
import { httpClient } from '../lib/http-client';

export interface Payment {
  id: string;
  amount: string;
  currency_id: CurrencyId;
  payment_status_id:
    | 'Created'
    | 'Pending'
    | 'Failed'
    | 'Succeed'
    | 'Chargeback';
}

export const getPayment = async (id: string) => {
  const r = await httpClient.get<Payment>(`payments/${id}`);

  return r.data;
};

export const pay = async (params: {
  payment_id: string;
  card_number: string;
  card_holder_name: string;
  cvv: string;
  expiration_month: string;
  expiration_year: string;
}) => {
  const r = await httpClient.post<{ id: string }>('payments/pay', params);

  return r.data;
};
