import { FC } from "react";

type VISAIconProps = {
  className?: string;
};
export const VISAIcon: FC<VISAIconProps> = ({ className }) => (
  <svg
    width="53"
    height="34"
    className={className}
    viewBox="0 0 53 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9225 23.7127L22.1893 10.401H25.8151L23.5466 23.7127H19.9225ZM36.6453 10.688C35.9271 10.4183 34.8014 10.1289 33.3959 10.1289C29.8133 10.1289 27.2897 11.9343 27.2683 14.5219C27.248 16.4348 29.0699 17.5018 30.445 18.1386C31.8564 18.791 32.3307 19.2071 32.3241 19.7898C32.3152 20.682 31.1971 21.0897 30.155 21.0897C28.7038 21.0897 27.9329 20.8879 26.7421 20.3909L26.2749 20.1794L25.7661 23.1594C26.6129 23.531 28.1789 23.8529 29.8048 23.8695C33.616 23.8695 36.0901 22.0848 36.1183 19.3216C36.1318 17.8073 35.166 16.6549 33.0742 15.7048C31.8069 15.089 31.0308 14.678 31.039 14.0545C31.039 13.5012 31.6959 12.9095 33.1153 12.9095C34.301 12.8911 35.1599 13.1499 35.8291 13.4195L36.1539 13.5732L36.6453 10.688ZM45.9754 10.4008H43.1737C42.3058 10.4008 41.6563 10.638 41.2752 11.5048L35.8906 23.704H39.6979C39.6979 23.704 40.3203 22.0636 40.4611 21.7035C40.8772 21.7035 44.5757 21.7092 45.1045 21.7092C45.213 22.1752 45.5455 23.704 45.5455 23.704H48.9099L45.9754 10.4004V10.4008ZM41.5303 18.9967C41.8301 18.2297 42.9749 15.2756 42.9749 15.2756C42.9534 15.3111 43.2724 14.5048 43.4556 14.005L43.7005 15.1528C43.7005 15.1528 44.3949 18.3304 44.5399 18.9967H41.5303ZM16.8445 10.4008L13.2949 19.4788L12.9166 17.634C12.2558 15.5074 10.197 13.2035 7.89535 12.05L11.141 23.6916L14.9771 23.6872L20.6851 10.4007H16.8445"
      fill="#3F4950"
    />
    <path
      d="M9.98292 10.4026H4.13655L4.09027 10.6796C8.63863 11.7815 11.6482 14.4443 12.8978 17.6435L11.6265 11.5262C11.407 10.6833 10.7705 10.4318 9.98306 10.4023"
      fill="#818891"
    />
  </svg>
);
