"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrencyShortHand = void 0;
const currenciesShortHand = {
    RUB: '₽',
    USD: '$',
    KZT: '₸',
    EUR: '€',
};
const getCurrencyShortHand = (currency_id) => {
    return currenciesShortHand[currency_id];
};
exports.getCurrencyShortHand = getCurrencyShortHand;
