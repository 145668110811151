import { DOMAttributes, FC } from 'react';
import { useLoaderData, useNavigate } from 'react-router';
import { MasterCardIcon } from '../icons/MasterCardIcon';
import { MIRIcon, PCIIcon, QiwiIcon, VISAIcon } from '../icons';
import styles from './Payment.module.css';
import { CardNumberInput, CVVInput, Page, ValidityInput } from '../components';
import { getPayment, pay, Payment } from '../api/payment';
import { InformationPage } from './informationPage';

export const paymentLoader = ({ params }: any) => {
  return getPayment(params.payment_id);
};

export const PaymentRoute: FC = (props) => {
  const { id, amount, currency_id, payment_status_id } =
    useLoaderData() as Payment;

  if (payment_status_id === 'Succeed') {
    return (
      <InformationPage
        type="success"
        informationText="The operation was successful, you can return back."
      />
    );
  }
  if (payment_status_id === 'Failed') {
    return (
      <InformationPage
        type="error"
        informationText="Something went wrong try again later, you need to go back."
      />
    );
  }
  if (payment_status_id === 'Pending') {
    return (
      <InformationPage
        type="pending"
        informationText="Your payment is pending."
      />
    );
  }

  return (
    <Page>
      <div>
        <Page.Header
          title="To pay"
          amount={Number(amount)}
          currency_id={currency_id}
        />

        <Page.Content>
          <div className={styles.paymentCardContent}>
            <PaymentForm paymentId={id} />
            <PaymentDescription />
          </div>
        </Page.Content>
      </div>

      <footer>
        <PCIIcon className={styles.pciIcon} />
      </footer>
    </Page>
  );
};

type PaymentFormProps = {
  paymentId: string;
};

const PaymentForm: FC<PaymentFormProps> = ({ paymentId }) => {
  const navigate = useNavigate();

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = async (
    e
  ) => {
    e.preventDefault();
    const targetForm: any = e.target;

    const [month, year] = targetForm[1].value.split('/');
    const formInput = {
      payment_id: paymentId,
      card_number: targetForm[0].value.replaceAll(' ', ''),
      card_holder_name: targetForm[3].value,
      cvv: targetForm[2].value,
      expiration_month: month,
      expiration_year: year,
    };

    try {
      await pay(formInput);
      navigate('/payments/success');
    } catch {
      navigate('/payments/error');
    }
  };

  return (
    <div className={styles.paymentForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formInput}>
          <label>Card number</label>
          <CardNumberInput
            placeholder="Select"
            className={styles.formControl}
            required
          />
        </div>

        <div className={styles.row}>
          <div className={styles.formInput}>
            <label>Validity</label>
            <ValidityInput className={styles.formControl} required />
          </div>
          <div className={styles.formInput}>
            <label>CVV</label>
            <CVVInput
              placeholder="Select"
              className={styles.formControl}
              required
            />
          </div>
        </div>

        <div className={styles.formInput}>
          <label>Cardowner</label>
          <input
            autoComplete="cc-name"
            type={'text'}
            placeholder="Select"
            className={styles.formControl}
            required
          />
        </div>
        <input type={'submit'} value={'Pay'} />
      </form>
    </div>
  );
};

const PaymentDescription: FC = () => (
  <div className={styles.paymentDescription}>
    <p>
      If your bank supports the technology of secure online payments Verified By
      Visa or MasterCard Secure Code, you may also need to enter a special
      password to make a payment. You can check the methods and the possibility
      of obtaining passwords for making Internet payments at the bank that
      issued the card. This site supports 128-bit encryption. Payments by bank
      cards are carried out in strict accordance with the requirements of Visa
      payment.
    </p>
    <div className={styles.paymentDescriptionIcons}>
      <VISAIcon />
      <MasterCardIcon />
      <QiwiIcon />
      <MIRIcon />
    </div>
  </div>
);
