import { FC, useMemo } from 'react';
import { CreationIcon, ErrorIcon, SuccessIcon } from '../icons';
import styles from './InformationPage.module.css';

type InformationPageProps = {
  type: 'not_found' | 'error' | 'success' | 'default' | 'creation' | 'pending';
  subHeaderText?: string;
  informationText: string;
};
export const InformationPage: FC<InformationPageProps> = ({
  type,
  subHeaderText,
  informationText,
}) => {
  const backgroundContent = useMemo(() => {
    switch (type) {
      case 'pending':
        return (
          <div className={styles.backgroundContent}>
            {/* Creation icon */}
            <svg
              width="517"
              height="517"
              viewBox="0 0 517 517"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M484.696 258.448H452.384C449.845 258.448 447.768 260.525 447.768 263.064V447.707H69.2498V69.1892H253.893C256.432 69.1892 258.509 67.1119 258.509 64.5731V32.2606C258.509 29.7218 256.432 27.6445 253.893 27.6445H46.1695C35.9564 27.6445 27.7052 35.8958 27.7052 46.1088V470.787C27.7052 481 35.9564 489.252 46.1695 489.252H470.848C481.061 489.252 489.312 481 489.312 470.787V263.064C489.312 260.525 487.235 258.448 484.696 258.448Z"
                fill="#24292D"
              />
              <path
                d="M168.434 271.662L167.338 340.268C167.28 345.403 171.434 349.616 176.57 349.616H176.8L244.888 347.942C246.042 347.884 247.196 347.423 248.003 346.615L487.981 107.156C489.77 105.368 489.77 102.425 487.981 100.636L416.259 28.9717C415.336 28.0484 414.182 27.6445 412.97 27.6445C411.759 27.6445 410.604 28.1061 409.681 28.9717L169.761 268.43C168.928 269.302 168.454 270.456 168.434 271.662ZM205.074 285.279L412.97 77.8443L439.051 103.867L231.039 311.418L204.67 312.052L205.074 285.279Z"
                fill="#24292D"
              />
            </svg>
          </div>
        );
      case 'creation':
        return (
          <div className={styles.backgroundContent}>
            {/* Creation icon */}
            <svg
              width="517"
              height="517"
              viewBox="0 0 517 517"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M484.696 258.448H452.384C449.845 258.448 447.768 260.525 447.768 263.064V447.707H69.2498V69.1892H253.893C256.432 69.1892 258.509 67.1119 258.509 64.5731V32.2606C258.509 29.7218 256.432 27.6445 253.893 27.6445H46.1695C35.9564 27.6445 27.7052 35.8958 27.7052 46.1088V470.787C27.7052 481 35.9564 489.252 46.1695 489.252H470.848C481.061 489.252 489.312 481 489.312 470.787V263.064C489.312 260.525 487.235 258.448 484.696 258.448Z"
                fill="#24292D"
              />
              <path
                d="M168.434 271.662L167.338 340.268C167.28 345.403 171.434 349.616 176.57 349.616H176.8L244.888 347.942C246.042 347.884 247.196 347.423 248.003 346.615L487.981 107.156C489.77 105.368 489.77 102.425 487.981 100.636L416.259 28.9717C415.336 28.0484 414.182 27.6445 412.97 27.6445C411.759 27.6445 410.604 28.1061 409.681 28.9717L169.761 268.43C168.928 269.302 168.454 270.456 168.434 271.662ZM205.074 285.279L412.97 77.8443L439.051 103.867L231.039 311.418L204.67 312.052L205.074 285.279Z"
                fill="#24292D"
              />
            </svg>

            {/* Creation text */}
            <svg
              width="1440"
              height="288"
              viewBox="0 0 1440 288"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.8237 287.796C45.0588 287.796 57.1276 286.558 67.0303 284.083C76.933 281.607 85.4431 278.203 92.5606 273.871L105.558 320.289C98.1308 324.622 87.6093 328.49 73.9931 331.894C60.377 335.298 43.8209 337 24.3251 337C2.35353 337 -17.916 333.596 -36.4835 326.788C-55.051 319.67 -71.1428 309.613 -84.759 296.616C-98.0657 283.309 -108.433 267.372 -115.86 248.804C-123.287 229.927 -127 208.73 -127 185.211C-127 162.311 -123.441 141.577 -116.324 123.01C-108.897 104.442 -98.6846 88.6598 -85.6873 75.6625C-72.3806 62.3558 -56.2888 52.1437 -37.4118 45.0262C-18.5349 37.9086 2.50826 34.3499 25.7176 34.3499C45.5229 34.3499 61.7695 36.2066 74.4573 39.9201C87.4545 43.6336 97.9761 47.6566 106.022 51.989L91.6322 98.8719C83.5863 94.5395 74.612 90.9807 64.7094 88.1956C55.1161 85.101 44.2851 83.5537 32.2162 83.5537C19.5285 83.5537 7.61432 85.4105 -3.52617 89.124C-14.6667 92.8375 -24.4146 98.8719 -32.77 107.227C-41.1254 115.273 -47.7787 125.795 -52.73 138.792C-57.3719 151.48 -59.6928 166.953 -59.6928 185.211C-59.6928 202.85 -57.3719 218.168 -52.73 231.165C-48.0882 243.853 -41.7443 254.375 -33.6984 262.73C-25.343 271.085 -15.7498 277.429 -4.91874 281.762C6.22176 285.785 18.1359 287.796 30.8237 287.796Z"
                fill="#24292D"
              />
              <path
                d="M147.016 331.894V110.477H194.827L201.79 150.397C207.05 138.637 215.406 128.27 226.856 119.296C238.306 110.322 252.696 105.835 270.025 105.835C277.143 105.835 283.487 106.608 289.057 108.156C294.627 109.703 299.269 111.56 302.982 113.726L292.77 162.466C289.366 160.609 285.034 159.062 279.773 157.824C274.512 156.276 268.323 155.503 261.206 155.503C246.042 155.503 233.354 160.609 223.142 170.821C213.24 180.724 208.288 197.125 208.288 220.025V331.894H147.016Z"
                fill="#24292D"
              />
              <path
                d="M513.379 237.2H366.232V239.056C366.232 256.696 372.421 269.848 384.799 278.512C397.177 287.177 413.115 291.51 432.61 291.51C444.989 291.51 455.82 290.272 465.104 287.796C474.697 285.32 483.362 282.381 491.098 278.977L502.239 322.146C493.883 326.169 483.362 329.573 470.674 332.358C457.986 335.453 442.977 337 425.648 337C408.937 337 393.155 334.834 378.301 330.501C363.756 325.859 350.913 318.897 339.773 309.613C328.942 300.329 320.277 288.57 313.778 274.335C307.28 260.1 304.031 243.079 304.031 223.274C304.031 205.635 306.661 189.698 311.922 175.463C317.182 160.918 324.609 148.54 334.203 138.328C344.105 128.116 355.865 120.225 369.481 114.654C383.097 108.775 398.261 105.835 414.971 105.835C444.989 105.835 468.817 114.964 486.456 133.222C504.405 151.48 513.379 177.01 513.379 209.813V237.2ZM450.25 194.959C450.25 189.079 449.476 183.354 447.929 177.784C446.691 172.213 444.525 167.417 441.43 163.394C438.335 159.062 434.467 155.658 429.825 153.182C425.183 150.706 419.458 149.468 412.65 149.468C400.272 149.468 390.06 153.646 382.014 162.001C373.968 170.357 369.172 181.652 367.624 195.887L450.25 194.959Z"
                fill="#24292D"
              />
              <path
                d="M560.519 129.972C573.826 122.236 587.906 116.356 602.76 112.333C617.614 108.001 633.397 105.835 650.108 105.835C663.414 105.835 675.483 107.382 686.314 110.477C697.455 113.571 706.893 118.522 714.63 125.331C722.675 131.829 728.71 140.339 732.733 150.861C737.065 161.073 739.232 173.451 739.232 187.996V331.894H691.42L685.85 309.149H683.993C676.876 317.814 667.747 324.622 656.606 329.573C645.775 334.524 633.087 337 618.543 337C606.474 337 595.798 335.298 586.514 331.894C577.23 328.18 569.339 323.384 562.84 317.504C556.342 311.315 551.39 304.043 547.986 295.687C544.892 287.332 543.344 278.358 543.344 268.764C543.344 256.077 545.975 245.246 551.236 236.271C556.806 226.988 564.388 219.406 573.981 213.526C583.574 207.646 594.715 203.314 607.402 200.529C620.4 197.744 634.325 196.351 649.179 196.351H677.03V182.89C677.03 172.678 673.781 165.251 667.282 160.609C661.093 155.657 652.274 153.182 640.824 153.182C631.85 153.182 622.566 154.574 612.973 157.359C603.689 160.145 593.322 164.632 581.872 170.821L560.519 129.972ZM677.495 234.879L657.535 235.343C638.658 235.962 625.506 239.211 618.079 245.091C610.652 250.661 606.938 257.469 606.938 265.515C606.938 276.037 610.033 283.309 616.222 287.332C622.721 291.045 629.993 292.902 638.039 292.902C646.085 292.902 653.666 290.581 660.784 285.939C668.211 281.298 673.781 275.882 677.495 269.693V234.879Z"
                fill="#24292D"
              />
              <path
                d="M928.446 155.039H869.494V257.16C869.494 267.681 871.815 274.954 876.457 278.977C881.408 282.69 887.907 284.547 895.953 284.547C902.142 284.547 908.486 283.928 914.984 282.69C921.483 281.452 926.744 279.75 930.767 277.584L940.051 320.753C934.79 323.229 926.899 325.705 916.377 328.18C905.855 330.656 894.715 331.894 882.956 331.894C856.033 331.894 836.846 326.633 825.396 316.112C813.946 305.28 808.221 289.189 808.221 267.836V155.039H773.871V110.477H808.221V42.2411H869.494V110.477H928.446V155.039Z"
                fill="#24292D"
              />
              <path
                d="M978.502 331.894V110.477H1040.24V331.894H978.502ZM1009.6 63.1295C999.081 63.1295 990.88 60.1896 985.001 54.3099C979.121 48.1207 976.181 40.539 976.181 31.5647C976.181 22.5904 979.121 15.1634 985.001 9.28374C990.88 3.09458 999.081 0 1009.6 0C1019.81 0 1027.86 3.09458 1033.74 9.28374C1039.93 15.1634 1043.02 22.5904 1043.02 31.5647C1043.02 40.539 1040.08 48.1207 1034.2 54.3099C1028.32 60.1896 1020.12 63.1295 1009.6 63.1295Z"
                fill="#24292D"
              />
              <path
                d="M1317.06 219.561C1317.06 237.819 1314.13 254.22 1308.25 268.764C1302.37 283.309 1294.17 295.687 1283.64 305.899C1273.43 315.802 1261.36 323.539 1247.44 329.109C1233.51 334.37 1218.5 337 1202.41 337C1186.32 337 1171.31 334.524 1157.38 329.573C1143.77 324.622 1131.85 317.349 1121.64 307.756C1111.74 298.163 1103.85 286.404 1097.97 272.478C1092.4 258.243 1089.61 242.151 1089.61 224.202C1089.61 205.326 1092.55 188.615 1098.43 174.07C1104.31 159.216 1112.36 146.838 1122.57 136.935C1133.09 126.723 1145.32 118.987 1159.24 113.726C1173.17 108.465 1188.18 105.835 1204.27 105.835C1220.36 105.835 1235.21 108.31 1248.83 113.262C1262.76 118.213 1274.67 125.485 1284.57 135.079C1294.78 144.362 1302.68 156.122 1308.25 170.357C1314.13 184.592 1317.06 200.993 1317.06 219.561ZM1253.47 221.882C1253.47 209.194 1252.08 198.363 1249.29 189.388C1246.51 180.414 1242.8 173.142 1238.15 167.572C1233.51 162.001 1228.1 157.978 1221.91 155.503C1215.72 152.718 1209.37 151.325 1202.87 151.325C1196.38 151.325 1190.19 152.563 1184.31 155.039C1178.43 157.205 1173.17 161.073 1168.53 166.643C1163.88 171.904 1160.17 179.022 1157.38 187.996C1154.6 196.97 1153.21 208.265 1153.21 221.882C1153.21 233.95 1154.6 244.472 1157.38 253.446C1160.17 262.111 1163.88 269.229 1168.53 274.799C1173.48 280.369 1178.89 284.547 1184.77 287.332C1190.96 290.117 1197.3 291.51 1203.8 291.51C1210.3 291.51 1216.49 290.427 1222.37 288.26C1228.25 285.785 1233.51 281.762 1238.15 276.191C1242.8 270.621 1246.51 263.504 1249.29 254.839C1252.08 245.865 1253.47 234.879 1253.47 221.882Z"
                fill="#24292D"
              />
              <path
                d="M1422.71 331.894H1360.97V110.477H1409.25L1416.68 138.792C1426.89 128.27 1438.03 120.225 1450.1 114.654C1462.48 108.775 1476.09 105.835 1490.95 105.835C1515.39 105.835 1534.12 112.488 1547.11 125.795C1560.11 139.101 1566.61 158.443 1566.61 183.818V331.894H1504.87V190.317C1504.87 178.867 1502.09 169.893 1496.52 163.394C1490.95 156.586 1482.75 153.182 1471.91 153.182C1463.87 153.182 1455.36 155.503 1446.38 160.145C1437.72 164.477 1429.83 170.511 1422.71 178.248V331.894Z"
                fill="#24292D"
              />
            </svg>
          </div>
        );
      case 'not_found':
        return (
          <div className={styles.backgroundContent}>
            {/* 404 */}
            <svg
              width="1093"
              height="450"
              viewBox="0 0 1093 450"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M134.855 7.59201H241.118V271.242H304.6V342.331H241.118V442.408H153.485V342.331H-48V283.666L134.855 7.59201ZM116.224 165.644L45.8426 271.242H153.485V173.236L157.626 89.7239H156.246L116.224 165.644Z"
                fill="#24292D"
              />
              <path
                d="M523.842 0C549.603 0 573.294 4.37116 594.914 13.1135C616.995 21.3957 635.855 34.7393 651.496 53.1442C667.596 71.089 680.017 94.3251 688.757 122.853C697.957 150.92 702.557 184.739 702.557 224.31C702.557 263.42 697.727 297.239 688.067 325.767C678.407 353.834 665.296 377.071 648.736 395.475C632.175 413.88 613.085 427.684 591.464 436.886C569.844 445.629 547.073 450 523.152 450C497.392 450 473.471 445.859 451.39 437.577C429.77 428.834 410.909 415.261 394.809 396.856C378.708 378.451 366.058 354.985 356.858 326.457C348.118 297.929 343.747 263.88 343.747 224.31C343.747 186.58 348.578 153.681 358.238 125.614C368.358 97.0859 381.698 73.6196 398.259 55.2147C414.819 36.8098 433.91 23.0061 455.53 13.8037C477.611 4.60122 500.382 0 523.842 0ZM438.28 224.31C438.28 255.138 440.58 280.675 445.18 300.92C449.78 321.166 455.99 337.27 463.811 349.233C472.091 361.196 481.291 369.709 491.411 374.77C501.992 379.371 512.802 381.672 523.842 381.672C534.883 381.672 545.463 379.141 555.583 374.08C566.163 369.018 575.134 360.506 582.494 348.543C590.314 336.58 596.524 320.475 601.124 300.23C605.725 279.985 608.025 254.678 608.025 224.31C608.025 193.482 605.725 168.175 601.124 148.39C596.524 128.144 590.314 112.27 582.494 100.767C575.134 88.8037 566.163 80.5215 555.583 75.9202C545.463 70.8589 534.883 68.3282 523.842 68.3282C512.802 68.3282 501.992 70.8589 491.411 75.9202C481.291 80.5215 472.091 88.8037 463.811 100.767C455.99 112.73 449.78 128.834 445.18 149.08C440.58 168.865 438.28 193.942 438.28 224.31Z"
                fill="#24292D"
              />
              <path
                d="M923.255 7.59201H1029.52V271.242H1093V342.331H1029.52V442.408H941.886V342.331H740.4V283.666L923.255 7.59201ZM904.625 165.644L834.243 271.242H941.886V173.236L946.026 89.7239H944.646L904.625 165.644Z"
                fill="#24292D"
              />
            </svg>
            {/* Not found */}
            <svg
              width="1440"
              height="267"
              viewBox="0 0 1440 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-117 31.2603H-70.9889L8.88644 153.36C14.2851 161.696 19.1929 169.909 23.61 178C28.2725 185.846 31.5853 191.975 33.5484 196.388H34.2846C32.8122 180.452 32.0761 164.638 32.0761 148.946C32.0761 133.01 32.0761 116.95 32.0761 100.769V31.2603H78.8234V262.955H32.8122L-41.5417 150.417C-44.7319 145.514 -47.922 140.488 -51.1121 135.339C-54.0568 129.945 -56.8788 124.796 -59.5781 119.893C-62.2774 114.989 -64.7314 110.576 -66.9399 106.653C-68.903 102.485 -70.3754 99.0523 -71.357 96.3554H-72.0931C-70.8662 113.273 -70.2527 129.577 -70.2527 145.269C-70.0073 160.715 -69.8846 176.529 -69.8846 192.711V262.955H-117V31.2603Z"
                fill="#24292D"
              />
              <path
                d="M297.071 173.955C297.071 188.42 294.74 201.415 290.078 212.938C285.415 224.461 278.912 234.269 270.569 242.359C262.471 250.205 252.901 256.335 241.858 260.748C230.815 264.916 218.914 267 206.153 267C193.393 267 181.491 265.039 170.449 261.116C159.651 257.193 150.204 251.431 142.106 243.831C134.253 236.23 127.996 226.913 123.333 215.88C118.916 204.602 116.708 191.853 116.708 177.632C116.708 162.676 119.039 149.437 123.701 137.913C128.364 126.145 134.744 116.337 142.842 108.492C151.185 100.401 160.878 94.2713 171.921 90.1033C182.964 85.9353 194.865 83.8512 207.626 83.8512C220.386 83.8512 232.165 85.8127 242.962 89.7355C254.005 93.6584 263.453 99.4201 271.305 107.021C279.403 114.376 285.661 123.693 290.078 134.971C294.74 146.249 297.071 159.244 297.071 173.955ZM246.643 175.793C246.643 165.741 245.539 157.16 243.33 150.05C241.122 142.939 238.177 137.178 234.496 132.764C230.815 128.351 226.521 125.164 221.613 123.202C216.705 120.996 211.675 119.893 206.521 119.893C201.368 119.893 196.46 120.873 191.798 122.835C187.135 124.551 182.964 127.616 179.283 132.029C175.602 136.197 172.657 141.836 170.449 148.946C168.24 156.056 167.136 165.006 167.136 175.793C167.136 185.355 168.24 193.691 170.449 200.802C172.657 207.667 175.602 213.306 179.283 217.719C183.209 222.132 187.504 225.442 192.166 227.649C197.074 229.855 202.104 230.959 207.258 230.959C212.411 230.959 217.319 230.101 221.981 228.384C226.644 226.423 230.815 223.236 234.496 218.822C238.177 214.409 241.122 208.77 243.33 201.905C245.539 194.795 246.643 186.091 246.643 175.793Z"
                fill="#24292D"
              />
              <path
                d="M436.796 122.835H390.048V203.744C390.048 212.08 391.889 217.842 395.57 221.029C399.496 223.971 404.649 225.442 411.029 225.442C415.937 225.442 420.968 224.952 426.121 223.971C431.274 222.99 435.446 221.642 438.636 219.926L445.998 254.128C441.826 256.09 435.569 258.051 427.225 260.012C418.882 261.974 410.048 262.955 400.723 262.955C379.374 262.955 364.159 258.786 355.08 250.45C346 241.869 341.461 229.12 341.461 212.202V122.835H314.222V87.5289H341.461V33.4669H390.048V87.5289H436.796V122.835Z"
                fill="#24292D"
              />
              <path
                d="M629.05 0C637.884 0 646.596 1.22589 655.185 3.67768C663.773 5.88429 671.258 8.82644 677.638 12.5041L668.068 44.5C663.896 42.5386 659.724 40.9449 655.553 39.719C651.381 38.2479 646.841 37.5124 641.933 37.5124C633.345 37.5124 626.964 40.0868 622.793 45.2355C618.621 50.1391 616.535 58.5978 616.535 70.6116V87.5289H660.706V122.835H616.535V262.955H567.948V122.835H541.077V87.5289H567.948V60.3141C567.948 40.6997 573.469 25.7438 584.512 15.4463C595.554 5.14876 610.401 0 629.05 0Z"
                fill="#24292D"
              />
              <path
                d="M856.754 173.955C856.754 188.42 854.422 201.415 849.76 212.938C845.098 224.461 838.595 234.269 830.251 242.359C822.153 250.205 812.583 256.335 801.54 260.748C790.498 264.916 778.596 267 765.836 267C753.075 267 741.174 265.039 730.131 261.116C719.334 257.193 709.886 251.431 701.788 243.831C693.936 236.23 687.678 226.913 683.016 215.88C678.599 204.602 676.39 191.853 676.39 177.632C676.39 162.676 678.721 149.437 683.384 137.913C688.046 126.145 694.426 116.337 702.524 108.492C710.868 100.401 720.561 94.2713 731.603 90.1033C742.646 85.9353 754.548 83.8512 767.308 83.8512C780.068 83.8512 791.847 85.8127 802.645 89.7355C813.687 93.6584 823.135 99.4201 830.987 107.021C839.085 114.376 845.343 123.693 849.76 134.971C854.422 146.249 856.754 159.244 856.754 173.955ZM806.325 175.793C806.325 165.741 805.221 157.16 803.013 150.05C800.804 142.939 797.859 137.178 794.179 132.764C790.498 128.351 786.203 125.164 781.295 123.202C776.388 120.996 771.357 119.893 766.204 119.893C761.051 119.893 756.143 120.873 751.48 122.835C746.818 124.551 742.646 127.616 738.965 132.029C735.284 136.197 732.34 141.836 730.131 148.946C727.922 156.056 726.818 165.006 726.818 175.793C726.818 185.355 727.922 193.691 730.131 200.802C732.34 207.667 735.284 213.306 738.965 217.719C742.891 222.132 747.186 225.442 751.848 227.649C756.756 229.855 761.787 230.959 766.94 230.959C772.093 230.959 777.001 230.101 781.663 228.384C786.326 226.423 790.498 223.236 794.179 218.822C797.859 214.409 800.804 208.77 803.013 201.905C805.221 194.795 806.325 186.091 806.325 175.793Z"
                fill="#24292D"
              />
              <path
                d="M1004.94 87.5289H1053.9V262.955H1017.46L1012.31 243.095H1010.83C1008.13 245.792 1004.94 248.612 1001.26 251.554C997.828 254.251 993.656 256.825 988.748 259.277C984.086 261.483 978.687 263.322 972.552 264.793C966.663 266.264 960.037 267 952.675 267C933.78 267 918.811 261.729 907.768 251.186C896.971 240.643 891.573 225.074 891.573 204.479V87.5289H940.528V200.434C940.528 210.977 942.982 218.577 947.89 223.236C953.043 227.894 959.546 230.223 967.399 230.223C976.233 230.223 983.718 228.384 989.852 224.707C996.233 221.029 1001.26 216.738 1004.94 211.835V87.5289Z"
                fill="#24292D"
              />
              <path
                d="M1146.86 262.955H1097.9V87.5289H1136.18L1142.07 109.963C1150.17 101.627 1159.01 95.2521 1168.58 90.8388C1178.39 86.1804 1189.19 83.8512 1200.97 83.8512C1220.35 83.8512 1235.2 89.1226 1245.51 99.6653C1255.81 110.208 1260.97 125.532 1260.97 145.636V262.955H1212.01V150.785C1212.01 141.713 1209.8 134.603 1205.39 129.455C1200.97 124.061 1194.47 121.364 1185.88 121.364C1179.5 121.364 1172.75 123.202 1165.63 126.88C1158.76 130.313 1152.5 135.094 1146.86 141.223V262.955Z"
                fill="#24292D"
              />
              <path
                d="M1419.04 0.367764H1468V262.955H1433.03L1427.88 243.831H1426.04C1421.62 249.96 1415.24 255.354 1406.9 260.012C1398.8 264.671 1388.12 267 1374.87 267C1363.83 267 1353.52 264.916 1343.95 260.748C1334.38 256.58 1326.04 250.573 1318.92 242.727C1311.81 234.882 1306.16 225.32 1301.99 214.041C1298.07 202.763 1296.1 189.891 1296.1 175.426C1296.1 161.45 1297.94 148.824 1301.62 137.545C1305.3 126.267 1310.58 116.705 1317.45 108.859C1324.32 100.769 1332.79 94.6391 1342.85 90.4711C1352.91 86.0579 1364.44 83.8512 1377.45 83.8512C1385.79 83.8512 1393.65 85.0771 1401.01 87.5289C1408.61 89.9807 1414.63 93.168 1419.04 97.0909V0.367764ZM1419.04 133.5C1415.85 129.577 1411.68 126.267 1406.53 123.57C1401.38 120.873 1395.73 119.525 1389.6 119.525C1383.71 119.525 1378.06 120.506 1372.67 122.467C1367.51 124.428 1362.85 127.616 1358.68 132.029C1354.75 136.197 1351.56 141.836 1349.11 148.946C1346.65 155.811 1345.43 164.27 1345.43 174.322C1345.43 184.129 1346.53 192.588 1348.74 199.698C1350.95 206.809 1353.89 212.693 1357.57 217.351C1361.25 222.01 1365.55 225.442 1370.46 227.649C1375.61 229.855 1381.01 230.959 1386.65 230.959C1394.51 230.959 1401.13 229.12 1406.53 225.442C1412.17 221.519 1416.34 216.983 1419.04 211.835V133.5Z"
                fill="#24292D"
              />
            </svg>
          </div>
        );
      case 'error':
        return (
          <div className={styles.backgroundContent}>
            {/* Error Icon */}
            <div>
              <svg
                width="517"
                height="517"
                viewBox="0 0 517 517"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M470.848 27.6445H46.1694C35.9563 27.6445 27.7051 35.8958 27.7051 46.1088V470.787C27.7051 481.001 35.9563 489.252 46.1694 489.252H470.848C481.061 489.252 489.312 481.001 489.312 470.787V46.1088C489.312 35.8958 481.061 27.6445 470.848 27.6445ZM447.768 447.707H69.2497V69.1892H447.768V447.707Z"
                  fill="#24292D"
                />
                <path
                  d="M168.172 150.894L150.903 168.163C149.368 169.698 149.333 171.199 150.8 172.666L237.159 259.024L151.395 344.787C149.86 346.322 149.826 347.823 151.293 349.29L167.795 365.792C169.261 367.259 170.762 367.224 172.297 365.689L258.061 279.926L344.419 366.285C345.886 367.751 347.387 367.717 348.922 366.182L366.191 348.913C367.726 347.378 367.76 345.877 366.293 344.41L279.935 258.052L365.712 172.275C367.247 170.74 367.281 169.239 365.814 167.772L349.312 151.271C347.846 149.804 346.345 149.838 344.81 151.373L259.033 237.15L172.674 150.791C171.208 149.325 169.707 149.359 168.172 150.894Z"
                  fill="#24292D"
                />
              </svg>
            </div>
            {/* Error Text */}
            <div>
              <svg
                width="966"
                height="288"
                viewBox="0 0 966 288"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-115 0H90.5608V53.0827H-46.3126V125.195H77.5253V177.777H-46.3126V262.409H92.0649V315.491H-115V0Z"
                  fill="#24292D"
                />
                <path
                  d="M139.495 315.491V76.6194H191.136L198.656 119.686C204.339 107 213.363 95.8159 225.73 86.1341C238.097 76.4524 253.64 71.6116 272.358 71.6116C280.045 71.6116 286.897 72.4462 292.914 74.1155C298.93 75.7847 303.944 77.7878 307.955 80.1248L296.925 132.707C293.248 130.704 288.568 129.034 282.886 127.699C277.204 126.03 270.519 125.195 262.832 125.195C246.454 125.195 232.75 130.704 221.719 141.721C211.024 152.404 205.676 170.098 205.676 194.803V315.491H139.495Z"
                  fill="#24292D"
                />
                <path
                  d="M334.852 315.491V76.6194H386.493L394.014 119.686C399.696 107 408.721 95.8159 421.088 86.1341C433.455 76.4524 448.997 71.6116 467.715 71.6116C475.402 71.6116 482.254 72.4462 488.271 74.1155C494.287 75.7847 499.301 77.7878 503.312 80.1248L492.282 132.707C488.605 130.704 483.926 129.034 478.243 127.699C472.561 126.03 465.876 125.195 458.189 125.195C441.811 125.195 428.107 130.704 417.077 141.721C406.381 152.404 401.033 170.098 401.033 194.803V315.491H334.852Z"
                  fill="#24292D"
                />
                <path
                  d="M750.114 194.303C750.114 214 746.939 231.694 740.588 247.385C734.237 263.076 725.38 276.431 714.016 287.448C702.986 298.131 689.95 306.477 674.909 312.487C659.868 318.162 643.657 321 626.276 321C608.896 321 592.685 318.329 577.644 312.988C562.937 307.646 550.068 299.8 539.038 289.451C528.342 279.101 519.819 266.415 513.469 251.392C507.452 236.034 504.444 218.674 504.444 199.31C504.444 178.945 507.619 160.917 513.97 145.226C520.321 129.201 529.011 115.847 540.041 105.164C551.405 94.1467 564.608 85.8003 579.649 80.1248C594.69 74.4493 610.901 71.6116 628.282 71.6116C645.663 71.6116 661.706 74.2824 676.413 79.624C691.454 84.9657 704.323 92.8112 715.018 103.161C726.049 113.176 734.572 125.863 740.588 141.22C746.939 156.577 750.114 174.271 750.114 194.303ZM681.427 196.807C681.427 183.119 679.923 171.434 676.914 161.752C673.906 152.07 669.895 144.225 664.882 138.215C659.868 132.206 654.019 127.866 647.334 125.195C640.649 122.19 633.797 120.688 626.778 120.688C619.758 120.688 613.074 122.023 606.723 124.694C600.372 127.031 594.69 131.204 589.676 137.214C584.663 142.889 580.652 150.568 577.644 160.25C574.635 169.931 573.131 182.117 573.131 196.807C573.131 209.827 574.635 221.178 577.644 230.86C580.652 240.207 584.663 247.886 589.676 253.895C595.024 259.905 600.874 264.412 607.224 267.417C613.909 270.421 620.761 271.924 627.78 271.924C634.8 271.924 641.484 270.755 647.835 268.418C654.186 265.747 659.868 261.407 664.882 255.398C669.895 249.388 673.906 241.71 676.914 232.362C679.923 222.68 681.427 210.828 681.427 196.807Z"
                  fill="#24292D"
                />
                <path
                  d="M797.54 315.491V76.6194H849.181L856.702 119.686C862.384 107 871.409 95.8159 883.776 86.1341C896.143 76.4524 911.685 71.6116 930.403 71.6116C938.091 71.6116 944.943 72.4462 950.959 74.1155C956.975 75.7847 961.989 77.7878 966 80.1248L954.97 132.707C951.293 130.704 946.614 129.034 940.932 127.699C935.249 126.03 928.565 125.195 920.877 125.195C904.499 125.195 890.795 130.704 879.765 141.721C869.069 152.404 863.721 170.098 863.721 194.803V315.491H797.54Z"
                  fill="#24292D"
                />
              </svg>
              <svg
                width="438"
                height="288"
                viewBox="0 0 438 288"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H205.561V53.0827H68.6874V125.195H192.525V177.777H68.6874V262.409H207.065V315.491H0V0Z"
                  fill="#24292D"
                />
                <path
                  d="M254.495 315.491V76.6194H306.136L313.656 119.686C319.339 107 328.363 95.8159 340.73 86.1342C353.097 76.4524 368.64 71.6116 387.358 71.6116C395.045 71.6116 401.897 72.4462 407.914 74.1155C413.93 75.7847 418.944 77.7878 422.955 80.1248L411.925 132.707C408.248 130.704 403.568 129.034 397.886 127.699C392.204 126.03 385.519 125.195 377.832 125.195C361.454 125.195 347.75 130.704 336.719 141.721C326.024 152.404 320.676 170.098 320.676 194.803V315.491H254.495Z"
                  fill="#24292D"
                />
                <path
                  d="M449.852 315.491V76.6194H501.493L509.014 119.686C514.696 107 523.721 95.8159 536.088 86.1342C548.455 76.4524 563.997 71.6116 582.715 71.6116C590.402 71.6116 597.254 72.4462 603.271 74.1155C609.287 75.7847 614.301 77.7878 618.312 80.1248L607.282 132.707C603.605 130.704 598.926 129.034 593.243 127.699C587.561 126.03 580.876 125.195 573.189 125.195C556.811 125.195 543.107 130.704 532.077 141.721C521.381 152.404 516.033 170.098 516.033 194.803V315.491H449.852Z"
                  fill="#24292D"
                />
                <path
                  d="M865.114 194.303C865.114 214 861.939 231.694 855.588 247.385C849.238 263.076 840.38 276.431 829.016 287.448C817.986 298.131 804.95 306.477 789.909 312.487C774.868 318.162 758.657 321 741.276 321C723.896 321 707.685 318.329 692.644 312.988C677.937 307.646 665.068 299.8 654.038 289.451C643.342 279.101 634.819 266.415 628.469 251.392C622.452 236.034 619.444 218.674 619.444 199.31C619.444 178.945 622.619 160.917 628.97 145.226C635.321 129.201 644.011 115.847 655.041 105.164C666.405 94.1467 679.608 85.8003 694.649 80.1248C709.69 74.4493 725.901 71.6116 743.282 71.6116C760.663 71.6116 776.706 74.2824 791.413 79.624C806.454 84.9657 819.323 92.8112 830.018 103.161C841.048 113.176 849.572 125.863 855.588 141.22C861.939 156.577 865.114 174.271 865.114 194.303ZM796.427 196.807C796.427 183.119 794.923 171.434 791.914 161.752C788.906 152.07 784.895 144.225 779.882 138.215C774.868 132.206 769.019 127.866 762.334 125.195C755.649 122.19 748.797 120.688 741.778 120.688C734.759 120.688 728.074 122.023 721.723 124.694C715.372 127.031 709.69 131.204 704.676 137.214C699.663 142.889 695.652 150.568 692.644 160.25C689.635 169.931 688.131 182.117 688.131 196.807C688.131 209.827 689.635 221.178 692.644 230.86C695.652 240.207 699.663 247.886 704.676 253.895C710.024 259.905 715.874 264.412 722.224 267.417C728.909 270.421 735.761 271.924 742.78 271.924C749.8 271.924 756.484 270.755 762.835 268.418C769.186 265.747 774.868 261.407 779.882 255.398C784.895 249.388 788.906 241.71 791.914 232.362C794.923 222.68 796.427 210.828 796.427 196.807Z"
                  fill="#24292D"
                />
                <path
                  d="M912.54 315.491V76.6194H964.181L971.702 119.686C977.384 107 986.409 95.8159 998.776 86.1342C1011.14 76.4524 1026.69 71.6116 1045.4 71.6116C1053.09 71.6116 1059.94 72.4462 1065.96 74.1155C1071.98 75.7847 1076.99 77.7878 1081 80.1248L1069.97 132.707C1066.29 130.704 1061.61 129.034 1055.93 127.699C1050.25 126.03 1043.56 125.195 1035.88 125.195C1019.5 125.195 1005.79 130.704 994.765 141.721C984.069 152.404 978.721 170.098 978.721 194.803V315.491H912.54Z"
                  fill="#24292D"
                />
              </svg>
            </div>
          </div>
        );
      case 'success':
        return (
          <div className={styles.backgroundContent}>
            {/* Success Icon */}
            <svg
              width="517"
              height="517"
              viewBox="0 0 517 517"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M212.968 342.553C214.666 344.924 216.905 346.856 219.499 348.188C222.093 349.521 224.967 350.216 227.884 350.216C230.8 350.216 233.674 349.521 236.268 348.188C238.862 346.856 241.101 344.924 242.799 342.553L364.317 174.067C366.51 171.009 364.317 166.739 360.567 166.739H333.505C327.62 166.739 322.023 169.566 318.56 174.413L227.912 300.201L186.829 243.192C183.367 238.403 177.828 235.518 171.885 235.518H144.823C141.073 235.518 138.88 239.788 141.073 242.846L212.968 342.553Z"
                fill="#24292D"
              />
              <path
                d="M470.848 27.6445H46.1694C35.9563 27.6445 27.7051 35.8958 27.7051 46.1088V470.787C27.7051 481 35.9563 489.252 46.1694 489.252H470.848C481.061 489.252 489.312 481 489.312 470.787V46.1088C489.312 35.8958 481.061 27.6445 470.848 27.6445ZM447.768 447.707H69.2497V69.1892H447.768V447.707Z"
                fill="#24292D"
              />
            </svg>

            {/* Success Text */}
            <svg
              width="1440"
              height="288"
              viewBox="0 0 1440 288"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-29.8779 26.0031C-16.9728 26.0031 -5.33513 27.2743 5.03506 29.8169C15.6357 32.1283 25.3145 35.4798 34.0716 39.8714L24.3927 72.4619C14.9443 67.8392 5.95685 64.3721 -2.56975 62.0607C-11.0964 59.7493 -19.5077 58.5936 -27.8039 58.5936C-39.5568 58.5936 -48.7747 61.0206 -55.4577 65.8745C-61.9103 70.7284 -65.1366 77.3158 -65.1366 85.6368C-65.1366 93.7267 -62.4864 99.9674 -57.1861 104.359C-51.6553 108.52 -42.8983 112.564 -30.9149 116.494L-9.82888 123.428C8.14611 129.438 21.8578 137.759 31.3062 148.391C40.7546 159.023 45.4788 171.62 45.4788 186.182C45.4788 195.659 43.6352 204.326 39.948 212.185C36.2608 220.044 30.9605 226.978 24.0471 232.988C17.1336 238.766 8.60702 243.273 -1.53272 246.509C-11.442 249.745 -22.734 251.363 -35.4087 251.363C-47.8529 251.363 -59.9515 250.207 -71.7043 247.896C-83.4572 245.354 -94.7492 241.424 -105.58 236.108L-95.9014 203.864C-85.9922 208.718 -76.3133 212.416 -66.8649 214.959C-57.4165 217.501 -47.5072 218.773 -37.137 218.773C-24.6928 218.773 -15.014 216.346 -8.10051 211.492C-0.956604 206.638 2.61535 199.588 2.61535 190.343C2.61535 187.107 2.03923 183.986 0.886986 180.981C-0.265259 177.977 -2.33929 175.203 -5.33513 172.661C-8.10051 169.887 -12.0181 167.344 -17.088 165.033C-21.9274 162.49 -28.2648 159.948 -36.1 157.405L-55.4577 150.818C-71.3587 145.271 -84.1486 137.99 -93.8274 128.975C-103.276 119.73 -108 106.786 -108 90.144C-108 70.9595 -101.317 55.4732 -87.951 43.6852C-74.5849 31.8971 -55.2273 26.0031 -29.8779 26.0031Z"
                fill="#24292D"
              />
              <path
                d="M188.369 83.2099H229.158V247.896H198.393L193.554 228.827H192.171C186.871 234.375 179.842 239.575 171.085 244.429C162.328 249.052 151.382 251.363 138.246 251.363C120.962 251.363 107.135 246.509 96.7653 236.801C86.3951 227.094 81.21 212.532 81.21 193.116V83.2099H121.999V188.956C121.999 200.282 124.534 208.371 129.604 213.225C134.905 217.848 141.588 220.159 149.653 220.159C158.18 220.159 165.554 218.426 171.776 214.959C178.229 211.261 183.76 206.753 188.369 201.437V83.2099Z"
                fill="#24292D"
              />
              <path
                d="M355.92 220.853C363.294 220.853 369.747 220.044 375.278 218.426C381.039 216.577 385.878 214.612 389.796 212.532L397.746 241.309C392.677 243.851 385.994 246.163 377.697 248.243C369.401 250.323 360.299 251.363 350.389 251.363C338.176 251.363 326.768 249.63 316.168 246.163C305.567 242.464 296.349 237.033 288.514 229.867C280.679 222.702 274.456 213.803 269.848 203.171C265.469 192.538 263.28 180.173 263.28 166.073C263.28 153.129 265.354 141.341 269.502 130.709C273.88 120.076 279.872 111.062 287.477 103.666C295.312 96.0381 304.53 90.2596 315.131 86.3303C325.962 82.1698 337.83 80.0895 350.735 80.0895C357.879 80.0895 365.253 80.7829 372.858 82.1698C380.463 83.5566 388.644 86.3303 397.401 90.4908L388.759 119.614C383.459 116.609 378.043 114.298 372.512 112.68C366.982 111.062 361.22 110.253 355.229 110.253C340.019 110.253 327.805 114.991 318.587 124.468C309.369 133.714 304.76 147.235 304.76 165.033C304.76 175.434 306.258 184.217 309.254 191.383C312.25 198.548 316.168 204.326 321.007 208.718C325.847 213.11 331.262 216.23 337.254 218.079C343.476 219.928 349.698 220.853 355.92 220.853Z"
                fill="#24292D"
              />
              <path
                d="M502.764 220.853C510.138 220.853 516.591 220.044 522.121 218.426C527.882 216.577 532.722 214.612 536.639 212.532L544.59 241.309C539.52 243.851 532.837 246.163 524.541 248.243C516.245 250.323 507.142 251.363 497.233 251.363C485.019 251.363 473.612 249.63 463.011 246.163C452.411 242.464 443.193 237.033 435.357 229.867C427.522 222.702 421.3 213.803 416.691 203.171C412.312 192.538 410.123 180.173 410.123 166.073C410.123 153.129 412.197 141.341 416.345 130.709C420.724 120.076 426.716 111.062 434.32 103.666C442.156 96.0381 451.374 90.2596 461.974 86.3303C472.805 82.1698 484.673 80.0895 497.578 80.0895C504.722 80.0895 512.097 80.7829 519.702 82.1698C527.306 83.5566 535.487 86.3303 544.244 90.4908L535.603 119.614C530.302 116.609 524.887 114.298 519.356 112.68C513.825 111.062 508.064 110.253 502.072 110.253C486.863 110.253 474.649 114.991 465.431 124.468C456.213 133.714 451.604 147.235 451.604 165.033C451.604 175.434 453.102 184.217 456.098 191.383C459.094 198.548 463.011 204.326 467.851 208.718C472.69 213.11 478.106 216.23 484.097 218.079C490.319 219.928 496.541 220.853 502.764 220.853Z"
                fill="#24292D"
              />
              <path
                d="M711.45 174.741H599.798V176.128C599.798 191.152 604.407 202.362 613.625 209.758C623.073 217.155 635.402 220.853 650.612 220.853C659.599 220.853 667.55 220.044 674.463 218.426C681.607 216.808 688.405 214.612 694.858 211.839L702.808 240.615C696.125 243.62 688.175 246.163 678.957 248.243C669.739 250.323 659.023 251.363 646.809 251.363C634.596 251.363 623.073 249.745 612.242 246.509C601.641 243.273 592.308 238.188 584.242 231.254C576.177 224.32 569.724 215.537 564.885 204.904C560.276 194.272 557.971 181.559 557.971 166.766C557.971 153.823 559.93 142.035 563.848 131.402C567.765 120.77 573.296 111.64 580.44 104.012C587.584 96.3848 596.226 90.4907 606.365 86.3303C616.505 82.1698 627.682 80.0895 639.896 80.0895C661.788 80.0895 679.187 86.7925 692.092 100.199C704.998 113.605 711.45 132.211 711.45 156.019V174.741ZM669.624 146.311C669.624 141.457 669.048 136.834 667.895 132.442C666.743 127.82 664.899 123.775 662.365 120.308C659.83 116.841 656.488 114.182 652.34 112.333C648.422 110.253 643.698 109.213 638.167 109.213C627.797 109.213 619.271 112.68 612.588 119.614C605.905 126.548 601.872 135.678 600.489 147.004L669.624 146.311Z"
                fill="#24292D"
              />
              <path
                d="M842.001 119.961C828.405 113.489 815.154 110.253 802.249 110.253C793.722 110.253 787.154 111.755 782.545 114.76C778.167 117.765 775.977 121.926 775.977 127.242C775.977 131.633 777.821 135.447 781.508 138.683C785.195 141.688 792.57 144.808 803.631 148.044L814.002 151.165C828.75 155.556 839.812 161.566 847.186 169.193C854.791 176.821 858.593 186.876 858.593 199.357C858.593 215.306 852.602 228.018 840.618 237.495C828.635 246.74 812.273 251.363 791.533 251.363C769.179 251.363 750.628 247.665 735.879 240.269L744.867 209.758C751.55 212.994 758.579 215.652 765.953 217.733C773.558 219.813 781.393 220.853 789.459 220.853C808.586 220.853 818.15 214.728 818.15 202.477C818.15 196.93 815.845 192.77 811.236 189.996C806.858 187.222 799.253 184.217 788.422 180.981L777.706 177.514C763.418 172.892 752.817 166.882 745.904 159.486C739.221 151.858 735.879 141.919 735.879 129.669C735.879 122.503 737.377 115.916 740.373 109.906C743.369 103.666 747.517 98.3495 752.817 93.9578C758.348 89.5662 764.916 86.2147 772.521 83.9033C780.356 81.3608 788.998 80.0895 798.446 80.0895C807.895 80.0895 816.997 81.0141 825.754 82.8632C834.742 84.4811 843.038 87.0237 850.643 90.4908L842.001 119.961Z"
                fill="#24292D"
              />
              <path
                d="M988.844 119.961C975.248 113.489 961.997 110.253 949.092 110.253C940.566 110.253 933.998 111.755 929.389 114.76C925.01 117.765 922.821 121.926 922.821 127.242C922.821 131.633 924.665 135.447 928.352 138.683C932.039 141.688 939.413 144.808 950.475 148.044L960.845 151.165C975.594 155.556 986.655 161.566 994.03 169.193C1001.63 176.821 1005.44 186.876 1005.44 199.357C1005.44 215.306 999.445 228.018 987.462 237.495C975.479 246.74 959.117 251.363 938.376 251.363C916.023 251.363 897.472 247.665 882.723 240.269L891.71 209.758C898.393 212.994 905.422 215.652 912.796 217.733C920.401 219.813 928.237 220.853 936.302 220.853C955.429 220.853 964.993 214.728 964.993 202.477C964.993 196.93 962.689 192.77 958.08 189.996C953.701 187.222 946.096 184.217 935.265 180.981L924.549 177.514C910.262 172.892 899.661 166.882 892.747 159.486C886.064 151.858 882.723 141.919 882.723 129.669C882.723 122.503 884.221 115.916 887.217 109.906C890.213 103.666 894.361 98.3495 899.661 93.9578C905.192 89.5662 911.759 86.2147 919.364 83.9033C927.199 81.3608 935.841 80.0895 945.29 80.0895C954.738 80.0895 963.841 81.0141 972.598 82.8632C981.585 84.4811 989.882 87.0237 997.486 90.4908L988.844 119.961Z"
                fill="#24292D"
              />
              <path
                d="M1103.89 0C1111.72 0 1119.44 1.04012 1127.05 3.12037C1134.65 5.20062 1141.45 7.85871 1147.44 11.0947L1139.49 38.1379C1135.34 36.2888 1131.19 34.7864 1127.05 33.6307C1123.13 32.475 1118.87 31.8971 1114.26 31.8971C1105.27 31.8971 1098.59 34.5552 1094.21 39.8714C1090.06 45.1876 1087.99 54.0864 1087.99 66.5679V83.2099H1132.23V113.027H1087.99V247.896H1047.54V113.027H1023V83.2099H1047.54V56.5134C1047.54 38.2534 1052.61 24.2695 1062.75 14.5617C1072.89 4.85391 1086.6 0 1103.89 0Z"
                fill="#24292D"
              />
              <path
                d="M1269.61 83.2099H1310.4V247.896H1279.63L1274.79 228.827H1273.41C1268.11 234.375 1261.08 239.575 1252.33 244.429C1243.57 249.052 1232.62 251.363 1219.49 251.363C1202.2 251.363 1188.38 246.509 1178.01 236.801C1167.64 227.094 1162.45 212.532 1162.45 193.116V83.2099H1203.24V188.956C1203.24 200.282 1205.78 208.371 1210.84 213.225C1216.15 217.848 1222.83 220.159 1230.89 220.159C1239.42 220.159 1246.79 218.426 1253.02 214.959C1259.47 211.261 1265 206.753 1269.61 201.437V83.2099Z"
                fill="#24292D"
              />
              <path
                d="M1354.89 247.896V1.04013H1394.99V247.896H1354.89Z"
                fill="#24292D"
              />
              <path
                d="M1440.97 247.896V1.04013H1481.07V247.896H1440.97Z"
                fill="#24292D"
              />
              <path
                d="M1507 83.2099H1551.25L1582.01 167.113L1593.42 206.638H1594.46L1605.52 166.766L1633.17 83.2099H1675L1613.82 256.564C1607.13 275.748 1598.15 292.275 1586.85 306.143C1575.56 320.011 1560.12 330.297 1540.53 337L1523.25 304.756C1535.46 300.133 1545.83 293.662 1554.36 285.341C1563.12 277.02 1569.45 266.387 1573.37 253.443L1575.1 247.549L1507 83.2099Z"
                fill="#24292D"
              />
            </svg>
          </div>
        );
      default:
        return <></>;
    }
  }, [type]);

  const mainHeader = useMemo(() => {
    switch (type) {
      case 'not_found':
        return <h1>404</h1>;
      case 'pending':
        return <CreationIcon fill="#FFD639" />;
      case 'creation':
        return <CreationIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
        return null;
    }
  }, [type]);

  const subHeader = useMemo(() => {
    if (subHeaderText) return subHeaderText;

    switch (type) {
      case 'not_found':
        return 'Not Found';
      case 'pending':
        return 'Pending';
      case 'creation':
        return 'Creation';
      case 'error':
        return 'Error';
      case 'success':
        return 'Successfully';
      default:
        return null;
    }
  }, [type, subHeaderText]);

  return (
    <div>
      {backgroundContent}
      <div className={styles.interactiveContent}>
        {mainHeader}
        <h2>{subHeader}</h2>
        <p>{informationText}</p>
        <a href="/">Home</a>
      </div>
    </div>
  );
};
