import { FC, InputHTMLAttributes, useEffect, useState } from 'react';

type CardNumberInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;
export const CardNumberInput: FC<CardNumberInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [val, setVal] = useState(value || '');

  useEffect(() => setVal(value || ''), [value]);

  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (
    e
  ) => {
    const inputVal = e.target.value;

    var digitString: string[] = [];
    var maxLength = 16;

    for (var i = 0; i < inputVal.length; i++) {
      // Если введена длина больше чем положена то все остальное обрезаем
      if (digitString.length === maxLength) break;

      var char = inputVal.charAt(i);

      // Если введено не число то обрезаем
      if (!isDigit(char)) continue;

      digitString.push(char);
    }

    setVal(formatToCardNumber(digitString.join('')));
    if (onChange) onChange(e);
  };
  return (
    <input
      {...props}
      pattern="[0-9\s]{19}"
      maxLength={19}
      autoComplete="cc-number"
      value={val}
      onChange={handleChange}
      type={'text'}
    />
  );
};

/**
 * Если строка состоит из цифр. то вернется true
 */
const isDigit = (val: string) => !isNaN(parseInt(val));

/**
 * Форматирует строку в формат номера карты
 */
const formatToCardNumber = (val: string) => {
  const formatedVal = val.replaceAll(' ', '');

  const result: string[] = [];

  for (let i = 0; i < formatedVal.length; i++) {
    if (i !== 0 && i % 4 === 0) {
      result.push(' ');
    }
    result.push(formatedVal[i]);
  }

  return result.join('');
};
