import { FC, PropsWithChildren } from 'react';
import { ContentPage } from './ContentPage';
import { Header } from './Header';
import styles from './Page.module.css';

export const Page: FC<PropsWithChildren> & {
  Content: typeof ContentPage;
  Header: typeof Header;
} = ({ children }) => (
  <div className={styles.page}>
    <div className={styles.pageContent}>{children}</div>
  </div>
);

Page.Content = ContentPage;
Page.Header = Header;
