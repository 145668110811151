import { FC, PropsWithChildren } from 'react';
import { SmartGatesIcon, SmartGatesLogoIcon } from '../../../icons';
import styles from './ContentPage.module.css';

export const ContentPage: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.contentPage}>
    <div className={styles.contentPageLogo}>
      <SmartGatesIcon />
      <SmartGatesLogoIcon />
    </div>
    {children}
  </div>
);
