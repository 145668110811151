import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { InformationPage } from './routes/informationPage';
import { paymentLoader, PaymentRoute } from './routes/payment';
import { PaymentErrorRoute } from './routes/paymentError';
import { Root } from './routes/root';
import './styles/index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: 'payments/success',
    element: (
      <InformationPage
        type="success"
        informationText="The operation was successful, you can return back."
      />
    ),
  },
  {
    path: 'payments/error',
    element: (
      <InformationPage
        type="error"
        informationText="Something went wrong try again later, you need to go back."
      />
    ),
  },
  {
    path: 'payments/:payment_id',
    element: <PaymentRoute />,
    loader: paymentLoader,
    errorElement: <PaymentErrorRoute />,
  },
  {
    path: '*',
    element: (
      <InformationPage type="not_found" informationText="Page not found" />
    ),
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
