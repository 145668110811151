"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyId = void 0;
var CurrencyId;
(function (CurrencyId) {
    CurrencyId["USD"] = "USD";
    CurrencyId["RUB"] = "RUB";
    CurrencyId["KZT"] = "KZT";
    CurrencyId["EUR"] = "EUR";
})(CurrencyId = exports.CurrencyId || (exports.CurrencyId = {}));
