"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeSanitizedMask = void 0;
const makeSanitizedMask = (card_number, char = '*') => {
    if (card_number.length !== 16) {
        throw new Error('Wrong card_number length');
    }
    return (card_number.substring(0, 6) +
        Array(6).fill(char).join('') +
        card_number.substring(12));
};
exports.makeSanitizedMask = makeSanitizedMask;
