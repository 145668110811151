import { getCurrencyShortHand } from 'common';
import { FC, useMemo } from 'react';
import styles from './Header.module.css';

type HeaderProps = { title: string; amount?: number; currency_id?: string };

export const Header: FC<HeaderProps> = ({ title, amount, currency_id }) => {
  const renderMoney = useMemo(() => {
    if (currency_id === undefined || amount === undefined) return <></>;

    return (
      <p className={styles.pageSum}>
        <span>{getCurrencyShortHand(currency_id)}</span>

        <span>
          {amount.toLocaleString('af', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </p>
    );
  }, [currency_id, amount]);
  return (
    <>
      <h1 className={styles.pageHeader}>{title}</h1>
      {renderMoney}
    </>
  );
};
