// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_pageHeader__FiQa4 {\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 33px;\n  color: var(--color-page-header);\n}\n\n.Header_pageSum__b4Agb {\n  margin-top: 0;\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 53px;\n  color: var(--color-page-sub-header);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Page/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mCAAmC;AACrC","sourcesContent":[".pageHeader {\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 33px;\n  color: var(--color-page-header);\n}\n\n.pageSum {\n  margin-top: 0;\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 53px;\n  color: var(--color-page-sub-header);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "Header_pageHeader__FiQa4",
	"pageSum": "Header_pageSum__b4Agb"
};
export default ___CSS_LOADER_EXPORT___;
