import { FC } from "react";

type MIRIconProps = {
  className?: string;
};
export const MIRIcon: FC<MIRIconProps> = ({ className }) => (
  <svg
    width="53"
    height="34"
    className={className}
    viewBox="0 0 53 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22401 10.8849C8.62466 10.8825 9.81537 10.7752 10.3197 12.4784C10.6595 13.6257 11.2007 15.505 11.9433 18.1164H12.2457C13.0422 15.3633 13.5893 13.484 13.887 12.4784C14.3967 10.7575 15.6707 10.8849 16.1803 10.8849L20.1121 10.8849V23.1228H16.1047V15.9108H15.836L13.6021 23.1228H10.587L8.35306 15.9055H8.08434V23.1228H4.07693V10.8849L8.22401 10.8849ZM25.8667 10.8849V18.1023H26.1864L28.9037 12.1675C29.4312 10.9865 30.5555 10.8849 30.5555 10.8849H34.4334V23.1228H30.3423V15.9055H30.0226L27.3586 21.8402C26.8311 23.0159 25.6535 23.1228 25.6535 23.1228H21.7756V10.8849H25.8667ZM48.5741 16.7005C48.0034 18.3185 46.2113 19.4773 44.2272 19.4773H39.9369V23.1228H36.0465V16.7005H48.5741Z"
      fill="#2D3439"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.4148 10.8867H35.8424C36.0464 13.6113 38.3914 15.9437 40.8189 15.9437H48.8444C49.3075 13.6793 47.7132 10.8867 44.4148 10.8867Z"
      fill="#818891"
    />
  </svg>
);
