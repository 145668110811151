import { FC, InputHTMLAttributes, useEffect, useState, useMemo } from 'react';

type CVVInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const CVVInput: FC<CVVInputProps> = ({ value, onChange, ...props }) => {
  const [val, setVal] = useState(value || '');

  useEffect(() => setVal(value || ''), [value]);

  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (
    e
  ) => {
    const inputVal = e.target.value;

    var digitString: string[] = [];
    var maxLength = 3;

    for (var i = 0; i < inputVal.length; i++) {
      // Если введена длина больше чем положена то все остальное обрезаем
      if (digitString.length === maxLength) break;

      var char = inputVal.charAt(i);

      // Если введено не число то обрезаем
      if (!isDigit(char)) continue;

      digitString.push(char);
    }

    setVal(digitString.join(''));
    if (onChange) onChange(e);
  };

  return (
    <input
      {...props}
      // pattern="[0-9]{3}"
      value={val}
      onChange={handleChange}
      minLength={3}
      maxLength={3}
      type="password"
      autoComplete="cc-csc"
    />
  );
};

/**
 * Если строка состоит из цифр. то вернется true
 */
const isDigit = (val: string) => !isNaN(parseInt(val));
