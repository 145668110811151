import axios from 'axios';
import { FC } from 'react';
import { useRouteError } from 'react-router-dom';
import { InformationPage } from './informationPage';

export const PaymentErrorRoute: FC = (props) => {
  const error = useRouteError() as Error;
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 404) {
      return (
        <InformationPage type="not_found" informationText="Payment not found" />
      );
    }
  }
  return <InformationPage type="error" informationText="Unexpected error" />;
};
