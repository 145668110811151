// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentPage_contentPage__kCYb0 {\n  background-color: var(--color-page-sub-background);\n  padding: 24px;\n  max-width: 490px;\n  min-height: 417px;\n  border-radius: 6px;\n}\n.ContentPage_contentPageLogo__dMQJV {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Page/ContentPage/ContentPage.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".contentPage {\n  background-color: var(--color-page-sub-background);\n  padding: 24px;\n  max-width: 490px;\n  min-height: 417px;\n  border-radius: 6px;\n}\n.contentPageLogo {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentPage": "ContentPage_contentPage__kCYb0",
	"contentPageLogo": "ContentPage_contentPageLogo__dMQJV"
};
export default ___CSS_LOADER_EXPORT___;
