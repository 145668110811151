import { FC } from 'react';

type CreationIconProps = {
  className?: string;
  fill?: string;
};
export const CreationIcon: FC<CreationIconProps> = ({
  className,
  fill = '#12BA55',
}) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.0014 39.9916H70.0014C69.6085 39.9916 69.2871 40.3131 69.2871 40.7059V69.2773H10.7157V10.7059H39.2871C39.6799 10.7059 40.0014 10.3845 40.0014 9.99163V4.99163C40.0014 4.59877 39.6799 4.27734 39.2871 4.27734H7.14422C5.56386 4.27734 4.28708 5.55413 4.28708 7.13449V72.8488C4.28708 74.4291 5.56386 75.7059 7.14422 75.7059H72.8585C74.4389 75.7059 75.7157 74.4291 75.7157 72.8488V40.7059C75.7157 40.3131 75.3942 39.9916 75.0014 39.9916Z"
      fill={fill}
    />
    <path
      d="M26.0633 42.0363L25.8936 52.6523C25.8847 53.447 26.5276 54.0988 27.3222 54.0988H27.3579L37.8936 53.8398C38.0722 53.8309 38.2508 53.7595 38.3758 53.6345L75.5097 16.5809C75.7865 16.3041 75.7865 15.8488 75.5097 15.572L64.4115 4.4827C64.2686 4.33984 64.0901 4.27734 63.9026 4.27734C63.7151 4.27734 63.5365 4.34877 63.3936 4.4827L26.2686 41.5363C26.1397 41.6711 26.0663 41.8497 26.0633 42.0363ZM31.7329 44.1434L63.9026 12.0452L67.9383 16.072L35.7508 48.1881L31.6704 48.2863L31.7329 44.1434Z"
      fill={fill}
    />
  </svg>
);
