import { FC } from "react";

type QiwiIconProps = {
  className?: string;
};
export const QiwiIcon: FC<QiwiIconProps> = ({ className }) => (
  <svg
    width="53"
    height="34"
    className={className}
    viewBox="0 0 53 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5001 29.2378C33.255 29.2378 38.7309 23.7578 38.7309 16.9978C38.7309 10.2378 33.255 4.75781 26.5001 4.75781C19.7452 4.75781 14.2693 10.2378 14.2693 16.9978C14.2693 23.7578 19.7452 29.2378 26.5001 29.2378Z"
      fill="#2D3439"
    />
    <path
      d="M31.5376 19.6331C31.5766 19.9398 31.4894 20.0584 31.3924 20.0584C31.2953 20.0584 31.1592 19.9398 31.014 19.7027C30.8687 19.4655 30.8099 19.1978 30.8878 19.0601C30.936 18.9706 31.043 18.9308 31.1691 18.9805C31.4214 19.08 31.5185 19.4648 31.5376 19.6331ZM30.1792 20.3063C30.4796 20.5633 30.5675 20.8594 30.4116 21.0774C30.3626 21.1346 30.3016 21.1803 30.2328 21.2109C30.1641 21.2416 30.0894 21.2567 30.0141 21.2549C29.8465 21.2565 29.6842 21.1967 29.5577 21.0866C29.2864 20.8494 29.2084 20.4539 29.3827 20.2367C29.4199 20.1906 29.4675 20.154 29.5216 20.1299C29.5757 20.1059 29.6347 20.095 29.6938 20.0982C29.849 20.0982 30.024 20.1671 30.1792 20.3055V20.3063ZM19.6202 16.1516C19.6202 12.3931 22.6091 9.34766 26.2967 9.34766C29.9851 9.34766 32.9739 12.3939 32.9739 16.1516C32.974 17.3781 32.6493 18.5828 32.0329 19.643C32.0131 19.6729 31.9641 19.6629 31.955 19.6231C31.7226 17.9516 30.7227 17.0321 29.2673 16.7552C29.1404 16.7353 29.1213 16.6557 29.2864 16.6366C29.7328 16.5968 30.3634 16.6067 30.6929 16.6664C30.713 16.4922 30.723 16.317 30.7227 16.1416C30.7227 13.6592 28.7429 11.6419 26.3066 11.6419C23.8712 11.6419 21.8913 13.6592 21.8913 16.1416C21.8913 18.6248 23.8712 20.6421 26.3066 20.6421H26.5107C26.4414 20.2406 26.412 19.8331 26.4228 19.4258C26.4328 19.1488 26.4916 19.1091 26.6078 19.3263C27.2194 20.405 28.0923 21.3742 29.8008 21.759C31.1982 22.0765 32.5956 22.4422 34.0999 24.3906C34.2352 24.5589 34.0319 24.7364 33.8767 24.5979C32.3433 23.2133 30.9459 22.7581 29.6747 22.7581C28.2475 22.7688 27.2775 22.957 26.2967 22.957C22.6091 22.957 19.6202 19.9092 19.6202 16.1508V16.1516Z"
      fill="#818891"
    />
  </svg>
);
