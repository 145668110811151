// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page_page__riGDs {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n}\n\n.Page_pageContent__Z-bZN {\n  display: grid;\n  grid-template-rows: 1fr auto;\n}\n\n@media (min-width: 320px) {\n  .Page_pageContent__Z-bZN {\n    padding: 12px;\n  }\n}\n@media (min-width: 481px) {\n  .Page_pageContent__Z-bZN {\n    padding: none;\n  }\n}\n\n.Page_pageContent__Z-bZN footer {\n  grid-row-start: 2;\n  grid-row-end: 3;\n  padding: 46px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Page/Page.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;AACf","sourcesContent":[".page {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n}\n\n.pageContent {\n  display: grid;\n  grid-template-rows: 1fr auto;\n}\n\n@media (min-width: 320px) {\n  .pageContent {\n    padding: 12px;\n  }\n}\n@media (min-width: 481px) {\n  .pageContent {\n    padding: none;\n  }\n}\n\n.pageContent footer {\n  grid-row-start: 2;\n  grid-row-end: 3;\n  padding: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "Page_page__riGDs",
	"pageContent": "Page_pageContent__Z-bZN"
};
export default ___CSS_LOADER_EXPORT___;
