import { FC } from 'react';

type ErrorIconProps = {
  className?: string;
};
export const ErrorIcon: FC<ErrorIconProps> = ({ className }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.8585 4.27734H7.14425C5.5639 4.27734 4.28711 5.55413 4.28711 7.13449V72.8488C4.28711 74.4291 5.5639 75.7059 7.14425 75.7059H72.8585C74.4389 75.7059 75.7157 74.4291 75.7157 72.8488V7.13449C75.7157 5.55413 74.4389 4.27734 72.8585 4.27734ZM69.2871 69.2773H10.7157V10.7059H69.2871V69.2773Z"
      fill="#EE321F"
    />
    <path
      d="M26.0229 23.3482L23.3507 26.0204C23.1132 26.2579 23.1079 26.4902 23.3349 26.7172L36.6979 40.0802L23.4269 53.3511C23.1894 53.5886 23.1841 53.8209 23.4111 54.0478L25.9646 56.6013C26.1915 56.8282 26.4238 56.823 26.6613 56.5854L39.9322 43.3145L53.2952 56.6775C53.5222 56.9045 53.7545 56.8992 53.992 56.6617L56.6642 53.9895C56.9017 53.752 56.907 53.5197 56.68 53.2927L43.317 39.9297L56.59 26.6567C56.8275 26.4192 56.8328 26.187 56.6058 25.96L54.0524 23.4065C53.8254 23.1796 53.5932 23.1848 53.3557 23.4224L40.0827 36.6954L26.7196 23.3324C26.4927 23.1054 26.2604 23.1107 26.0229 23.3482Z"
      fill="#EE321F"
    />
  </svg>
);
