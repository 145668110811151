import { FC, InputHTMLAttributes, useEffect, useState, useMemo } from 'react';

type ValidityInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  yearLength?: 2 | 4;
};

export const ValidityInput: FC<ValidityInputProps> = ({
  value,
  onChange,
  yearLength = 2,
  ...props
}) => {
  const [val, setVal] = useState(value || '');

  useEffect(() => setVal(value || ''), [value]);

  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (
    e
  ) => {
    const inputVal = e.target.value;

    var digitString: string[] = [];
    var maxLength = 2 + yearLength; // 2-а символа на меся и yearLength на год

    for (var i = 0; i < inputVal.length; i++) {
      // Если введена длина больше чем положена то все остальное обрезаем
      if (digitString.length === maxLength) break;

      var char = inputVal.charAt(i);

      // Если введено не число то обрезаем
      if (!isDigit(char)) continue;

      digitString.push(char);
    }

    setVal(formatToValidity(digitString.join('')));
    if (onChange) onChange(e);
  };

  const { pattern, placeholder } = useMemo(
    () => ({
      pattern: `\\d\\d\/${Array.from(Array(yearLength).keys())
        .map(() => '\\d')
        .join('')}`,
      placeholder: `mm/${Array.from(Array(yearLength).keys())
        .map(() => 'y')
        .join('')}`,
    }),
    [yearLength]
  );

  return (
    <input
      {...props}
      autoComplete="cc-exp"
      value={val}
      onChange={handleChange}
      type={'text'}
      pattern={pattern}
      placeholder={placeholder}
    />
  );
};

/**
 * Если строка состоит из цифр. то вернется true
 */
const isDigit = (val: string) => !isNaN(parseInt(val));

/**
 * Форматирует строку в формат MM\YYYY
 */
const formatToValidity = (val: string) => {
  // debugger;
  const formatedVal = val.replaceAll('/', '');

  const result: string[] = [];

  for (let i = 0; i < formatedVal.length; i++) {
    if (i === 2) {
      result.push('/');
    }
    result.push(formatedVal[i]);
  }

  return result.join('');
};
