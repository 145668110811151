"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatusId = void 0;
var PaymentStatusId;
(function (PaymentStatusId) {
    PaymentStatusId["Created"] = "Created";
    PaymentStatusId["Pending"] = "Pending";
    PaymentStatusId["Failed"] = "Failed";
    PaymentStatusId["Chargeback"] = "Chargeback";
})(PaymentStatusId = exports.PaymentStatusId || (exports.PaymentStatusId = {}));
